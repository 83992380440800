import { MetaArgs, MetaDescriptor } from '@remix-run/cloudflare';

export interface SeoInput {
  title: string;
  description?: string;
  keywords?: string;
  previewImageSrc?: string;
  previewImageAlt?: string;
}

export function getSeoMeta(
  { title, description, keywords, previewImageSrc, previewImageAlt }: SeoInput,
  args: MetaArgs,
  extraMeta?: MetaDescriptor[]
): MetaDescriptor[] {
  // Default fallback values for missing properties
  if (!previewImageSrc) {
    previewImageSrc =
      'https://public-assets.naamdeo.org/cdn-cgi/image/fit=scale-down,width=640,quality=75/images/cover.jpg';
  }
  if (!previewImageAlt) {
    previewImageAlt = 'Naam Deo';
  }
  if (!description) {
    description =
      'These hands are full with things holding us back for rather too long. They seek to be freed to enable hand-holding towards our highest purpose. - Naam Deo';
  }
  if (!keywords) {
    keywords =
      'Naamdeo, ask naam deo, highlights, videos, quotes, podcasts, Vihangam Yoga, Abhyas School Of Yoga, name tree, blog, events';
  }

  // Base meta tags
  const baseMeta: MetaDescriptor[] = [
    { title: `${title} | Naam Deo` },
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    {
      tagName: 'link',
      rel: 'canonical',
      href: new URL(args.location.pathname, 'https://courses.naamdeo.org').toString(),
    },
    { name: 'twitter:site', content: '@vybangalor' },
    { name: 'twitter:url', content: 'https://www.naamdeo.org' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { property: 'og:site_name', content: 'Naam Deo' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.naamdeo.org' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
  ];

  // Preview image meta tags
  const previewImageMeta: MetaDescriptor[] = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: previewImageSrc },
    { name: 'twitter:image:alt', content: previewImageAlt },
    { property: 'og:image', content: previewImageSrc },
    { property: 'og:image:alt', content: previewImageAlt },
    { property: 'og:image:url', content: previewImageSrc },
    { property: 'og:image:secure_url', content: 'https://public-assets.naamdeo.org/images/cover.jpg' },
  ];

  // Return the full meta descriptor array
  return [...baseMeta, ...previewImageMeta, ...(extraMeta ?? [])];
}
